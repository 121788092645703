<template>
  <div
    id="navbar"
    class="navbar-container d-flex align-items-center w-100 pt-0"
  >
    <b-navbar
      class="w-100 bg-transparent"
      toggleable="md"
    >
      <b-navbar-brand
        data-aos="fade-right"
      >
        <div class="brand-logo d-flex align-items-center justify-content-center">
          <b-img
            alt="logo"
            src="@/assets/images/logo/simi-connect-logo.png"
            class="logo-size-class"
          />
        </div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav
          class="ml-auto"
          data-aos="fade-left"
        >
          <b-nav-item
            active
            class="nav-item-style "
            @click="scrollToSection('home_page')"
          >
            Home
          </b-nav-item>
          <b-nav-item
            class="nav-item-style"
            @click="scrollToSection('about_us_section')"
          >
            About Us
          </b-nav-item>
          <b-nav-item
            class="nav-item-style"
            @click="scrollToSection('our_products')"
          >
            Products
          </b-nav-item>
          <!--          <b-nav-item-->
          <!--            class="nav-item-style"-->
          <!--            @click="scrollToSection('our_client')"-->
          <!--          >-->
          <!--            Clients-->
          <!--          </b-nav-item>-->
          <b-nav-item>
            <b-button
              pill
              variant="primary"
              @click="scrollToSection('contact_us')"
            >
              Contact Us
            </b-button>
          </b-nav-item>
          <b-nav-item>
            <div v-if="!$auth.loading">
              <b-button
                v-if="!$auth.isAuthenticated"
                :disabled="loginLoading"
                pill
                variant="primary"
                @click="login"
              >
                <b-spinner
                  v-if="loginLoading"
                  small
                />
                Login
              </b-button>
              <b-button
                v-if="$auth.isAuthenticated"
                :disabled="logoutLoading"
                pill
                variant="primary"
                @click="logout"
              >
                <b-spinner
                  v-if="logoutLoading"
                  small
                />
                Logout
              </b-button>
            </div>
          </b-nav-item>
          <b-nav-item>
            <div v-if="!$auth.loading && $auth.isAuthenticated">
              <b-button
                pill
                variant="primary"
                @click="goToDashboard()"
              >
                Dashboard
              </b-button>
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {
  BNavbarNav, BNavbar, BCollapse, BNavbarBrand, BNavbarToggle, BNavItem, BImg, BButton, BSpinner,
} from 'bootstrap-vue'
import 'animate.css'

export default {
  components: {
    BNavbarNav,
    BNavbar,
    BCollapse,
    BNavbarBrand,
    BNavbarToggle,
    BNavItem,
    BImg,
    BButton,
    BSpinner,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      logoutLoading: false,
      loginLoading: false,
    }
  },
  computed: {
    isSimmiUser() {
      return this.$store.getters['user/isSimmiUser']
    },
    isUserAdmin() {
      return this.$store.getters['user/isUserAdmin']
    },
  },
  mounted() {
    const loginQuery = this.$route.query
    if (loginQuery && loginQuery.login) {
      this.login()
    }
  },
  methods: {
    async login() {
      this.loginLoading = true
      await this.$auth.loginWithRedirect()
    },
    async logout() {
      this.logoutLoading = true
      await this.$store.dispatch('user/logoutUserWithRedirect')
      this.logoutLoading = false
    },
    goToDashboard() {
      if (this.isSimmiUser) {
        this.$router.push({ path: '/groups-listing' })
      }
      if (this.isUserAdmin) {
        this.$router.push({ path: '/admin/applications' })
      }
    },
    scrollToSection(id) {
      const element = document.getElementById(id)
      element.scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>
<style lang="scss">
#navbar {
  .nav-item-style {
    top: 62px;
    left: 1133px;
    width: 110px;
    height: 23px;
    color: #1F58B5;
    font-size: 14px;
    font-weight: bold;
    padding-top: 8px;
  }

  .nav-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  a:hover {
    background-color: rgb(34 41 47 / 3%) !important;
    border-radius: 20px !important;
  }

  .active.nav-link {
    background-color: rgb(34 41 47 / 3%) !important;
    border-radius: 20px !important;
  }
}

</style>
