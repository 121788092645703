<template>
  <div
    id="home_page"
    class="w-100 pt-3"
  >
    <div class="px-5">
      <NavBarLandingPage />
    </div>
    <div class="px-5">
      <home-landing-page />
    </div>
    <div class="p-0 pt-3">
      <AboutUsLandingPage />
    </div>
    <div>
      <TabsLandingPage
        :tab-data="adminPanelData"
      />
    </div>
    <div>
      <TabsLandingPage :tab-data="iotPanelData" />
    </div>
    <div>
      <TabsLandingPage :tab-data="androidPanelData" />
    </div>
    <!--    <div>-->
    <!--      <OurClients />-->
    <!--    </div>-->
    <div class="mt-2">
      <Footer />
    </div>
  </div>
</template>

<script>

import NavBarLandingPage from '@/views/landingPage/NavBarLandingPage.vue'
import HomeLandingPage from '@/views/landingPage/HomeLandingPage.vue'
import AboutUsLandingPage from '@/views/landingPage/AboutUsLandingPage.vue'
import TabsLandingPage from '@/components/TabsLandingPage.vue'
// import OurClients from '@/views/landingPage/OurClients.vue'
import Footer from '@/views/landingPage/Footer.vue'

export default {
  components: {
    // OurClients,
    TabsLandingPage,
    AboutUsLandingPage,
    HomeLandingPage,
    NavBarLandingPage,
    Footer,
  },
  async beforeRouteEnter(to, from, next) {
    if (!(process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'dev')) {
      next({
        path: '/groups-listing',
      })
      return
    }
    next()
  },
  data() {
    return {
      adminPanelData: {
        pageTitle: 'Our Products',
        title: 'Admin Panel',
        isActive: true,
        items: [{
          title: 'Dashboard',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/dashboard.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/DashboardWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/dashboard1.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'One place to find all the tasks that matter to you. Yours, and your team\'s. Quickly check your daily or weekly to-do list or search, sort and filter by Team, Project, Tags and Flags to find any task. Star smart filters (Subscribed, Delegated, Flagged and Unassigned) to add them to your favorites bar. Star teammates to add them to your favorites bar.',
        }, {
          title: 'Profiles',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/LauncherProfiles.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/LauncherProfilesWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/profileImg.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'text here detail profiles',
        }, {
          title: 'Media Storage',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/LauncherMediaStorage.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/LauncherMediaWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/mediaStorage.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'One place to find all the tasks that matter to you. Yours, and your team\'s. Quickly check your daily or weekly to-do list or search, sort and filter by Team, Project, Tags and Flags to find any task. Star smart filters (Subscribed, Delegated, Flagged and Unassigned) to add them to your favorites bar. Star teammates to add them to your favorites bar.',
        }, {
          title: 'Devices',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/devices.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/devicesWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/Devices.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'One place to find all the tasks that matter to you. Yours, and your team\'s. Quickly check your daily or weekly to-do list or search, sort and filter by Team, Project, Tags and Flags to find any task. Star smart filters (Subscribed, Delegated, Flagged and Unassigned) to add them to your favorites bar. Star teammates to add them to your favorites bar.',
        }, {
          title: 'Internal Network Ads',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/BanerAds.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/BannerAdsWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/internalNetworkAds.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'text here detail banner',
        },
        {
          title: 'Target Ads',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/TargetAds.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/TargetAdsWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/targetAd.png'),
          tabName: 'Dashboard',
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'text here detail target',
        }],
      },
      // IOT App
      iotPanelData: {
        pageTitle: 'Smart App',
        isActive: true,
        items: [{
          title: 'Lights',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/Lights.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/LightWhite.svg'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/lights.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'One place to find all the tasks that matter to you. Yours, and your team\'s. Quickly check your daily or weekly to-do list or search, sort and filter by Team, Project, Tags and Flags to find any task. Star smart filters (Subscribed, Delegated, Flagged and Unassigned) to add them to your favorites bar. Star teammates to add them to your favorites bar.',
        }, {
          title: 'Thermostat',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/Thermostat.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/ThermostatWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/thermostat.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'One place to find all the tasks that matter to you. Yours, and your team\'s. Quickly check your daily or weekly to-do list or search, sort and filter by Team, Project, Tags and Flags to find any task. Star smart filters (Subscribed, Delegated, Flagged and Unassigned) to add them to your favorites bar. Star teammates to add them to your favorites bar.',
        }, {
          title: 'Locks',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/Locks.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/LocksWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/locks.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'text here detail profiles',
        }],
      },
      // Android Launcher
      androidPanelData: {
        pageTitle: 'Android Launcher',
        isActive: true,
        items: [{
          title: 'All Allowed Apps',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/AllAllowedApps.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/AllAllowedAppsWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/allMediaAps.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'One place to find all the tasks that matter to you. Yours, and your team\'s. Quickly check your daily or weekly to-do list or search, sort and filter by Team, Project, Tags and Flags to find any task. Star smart filters (Subscribed, Delegated, Flagged and Unassigned) to add them to your favorites bar. Star teammates to add them to your favorites bar.',
        }, {
          title: 'Media Storage',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/AdminMediaStorage.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/AdminMediaStorageWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/androidMedia.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'One place to find all the tasks that matter to you. Yours, and your team\'s. Quickly check your daily or weekly to-do list or search, sort and filter by Team, Project, Tags and Flags to find any task. Star smart filters (Subscribed, Delegated, Flagged and Unassigned) to add them to your favorites bar. Star teammates to add them to your favorites bar.',
        }, {
          title: 'Profiles',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/landPageIcons/LauncherProfiles.svg'),
          // eslint-disable-next-line global-require
          iconActive: require('@/assets/images/landPageIcons/LauncherProfilesWhite.png'),
          // eslint-disable-next-line global-require
          mainImage: require('@/assets/images/landPageImg/androidProfiles.png'),
          tabHeading: 'Its much more than your regular tasks',
          tabContent: 'text here detail profiles',
        }],
      },
    }
  },
}
</script>
