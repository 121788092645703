<template>
  <div
    id="about_us_section"
    class="comp-div"
  >
    <b-row>
      <b-col
        cols="2"
        md="2"
      />
      <b-col
        cols="8"
        md="8"
      >
        <span
          class="heading-class"
          data-aos="flip-right"
        >About Us</span>
        <span
          class="p-class text-center"
          data-aos="zoom-in"
        >We make Android devices faster and tidier since 2012. Our apps support 99% of existing.</span>
        <span
          class="p-class"
          data-aos="zoom-in"
        >Android devices including tablets and tv boxes. In 2017 we reached over</span>
        <div class="img-class">
          <img
            class="w-100"
            data-aos="zoom-out-right"
            src="@/assets/images/landPageImg/mobile-screens.png"
          >
        </div>
      </b-col>
      <b-col
        cols="2"
        md="2"
      />
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import 'animate.css'

export default {
  components: {
    BCol,
    BRow,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss" scoped>
.comp-div {
  background-color: #DEDEDE;
}

.heading-class {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 3rem;
  font-weight: 900;
  height: 140px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-class {
  top: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #262626;
  opacity: 1;
}

.img-class {
  top: 1458px;
  left: 363px;
  opacity: 1;
}
</style>
