<template>
  <div class="m-0 bg-gray h-100">
    <b-row>
      <b-col
        class="h-100"
        md="5"
      >
        <div class="d-block mr-md-2 text-wrap">
          <div
            class="heading-class mt-md-5"
            data-aos="fade-right"
          >We're working on
            Incredible
            <span
              class="text-primary"
            >Patented</span>
            Smart
            Tech
          </div>
          <div
            class="paragraph-style text-wrap"
            data-aos="fade-up"
          >
            An
            Investment offer to sophisticated investors will be released soon.To speak to our authorized representatives
            register
            your interest here.
          </div>
          <!--          <div data-aos="zoom-in">-->
          <!--            <b-button-->
          <!--              class="btn-style"-->
          <!--              pill-->
          <!--              size="sm"-->
          <!--            >-->
          <!--              Learn more-->
          <!--            </b-button>-->
          <!--          </div>-->
        </div>
      </b-col>
      <b-col
        md="7"
      >
        <b-row class="mb-2">
          <b-col
            d-xs-none
            lg="1"
            md="1"
          />
          <b-col
            cols="6"
            lg="7"
            md="7"
            sm="6"
            style="height: 20rem;"
            xsm="6"
          >
            <b-img
              class="mt-1 mt-md-0"
              data-aos="fade-down-right"
              fluid-grow
              src="@/assets/images/landPageImg/iPhone.png"
              style="height: 20rem;"
            />
          </b-col>
          <b-col
            cols="6"
            lg="4"
            md="4"
            sm="6"
            style="height: 20rem;"
            xsm="6"
          >
            <b-img
              class="h-100 mt-1 mt-md-0"
              data-aos="fade-down-left"
              fluid-grow
              src="@/assets/images/landPageImg/2417.png"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            md="6"
          >
            <div class="h-100">
              <b-img
                class="img-2 h-100"
                data-aos="fade-up-right"
                fluid-grow
                src="@/assets/images/landPageImg/laptop.png"
              />
            </div>
          </b-col>
          <b-col
            cols="6"
            md="6"
          >
            <div class="h-100">
              <b-img
                class="img-class-1"
                data-aos="fade-up-left"
                fluid-grow
                src="@/assets/images/landPageImg/Group1.png"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BImg,
} from 'bootstrap-vue'
import 'animate.css'

export default {
  components: {
    BRow,
    BCol,
    BImg,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss" scoped>
.heading-class {
  top: 308px;
  left: 150px;
  font-family: 'Red Hat Display', sans-serif;
  color: black;
  left: 150px;
  max-width: 500px;
  text-align: left;
  font-size: 3rem;
  font-weight: 900;
}

.paragraph-style {
  left: 150px;
  max-width: 528px;
  min-height: 130px;
  text-align: left;
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: 0px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
  opacity: 1
}

.btn-style {
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
  top: 721px;
  left: 150px;
  width: 160px;
  min-height: 50px;
  background: #242021 0% 0% !important;
  box-shadow: 0px 18px 44px #00000029;
  font-family: 'Red Hat Display', sans-serif;
  color: #ffffff;
}

.img-class-2 {
  width: 12rem;
  max-height: 80%;
  border-radius: 20px;
  object-fit: contain;
}

.img-class-1 {
  width: 15rem;
  max-height: 80%;
  height: auto;
  border-radius: 20px;
}

.img-2 {
  width: 20rem;
  max-height: 80%;
  height: auto;
  border-radius: 20px;
  object-fit: cover
}

</style>
