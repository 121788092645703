<template>
  <div
    id="our_products"
    class="comp-div"
  >
    <b-row>
      <b-col
        cols="3"
        md="3"
      />
      <b-col
        cols="6"
        md="6"
      >
        <span
          class="heading-class"
          data-aos="flip-right"
        >{{ tabData.pageTitle }}</span>
        <span
          :class="tabData.title ? 'p-class': ''"
          data-aos="zoom-in"
        >{{ tabData.title }}</span>
        <span class="d-flex justify-content-center align-items-center ml-1 mr-1 mb-1">
          <div class="divider-class" />
        </span>
        <div class="img-class">
          <b-img
            :src="tabData.items[tabsIndex].mainImage"
            class="w-100"
            data-aos="zoom-out-right"
          />
        </div>
      </b-col>
      <b-col
        cols="3"
        md="3"
      />
    </b-row>
    <b-row
      class="bg-white tab_div"
    >
      <b-col
        cols="12"
        md="12"
      >
        <b-tabs
          v-model="tabsIndex"
          :vertical="vertical"
          data-aos="zoom-in-left"
          nav-wrapper-class="nav-vertical"
        >
          <b-tab
            v-for="(tab, i) in tabData.items"
            :key="tab.title"
          >
            <template
              #title
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center pl-lg-2 pr-lg-2"
              >
                <div class="icon">
                  <b-img
                    :src="i === tabsIndex ? tab.iconActive: tab.icon"
                    height="24px"
                    width="24px"
                  />
                </div>
                <div>
                  <span>{{ tab.title }}</span>
                </div>
              </div>
            </template>
            <div class="tab-body">
              <b-card-text class="d-flex justify-content-center align-items-center p-2"><b>{{ tab.tabHeading }}</b>
              </b-card-text>
              <b-row>
                <b-col
                  md="8"
                  offset-md="2"
                >
                  <b-card-text class="d-flex justify-content-center align-items-center pb-2 text-center">
                    {{ tab.tabContent }}
                  </b-card-text>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BTab, BTabs, BImg, BCardText,
} from 'bootstrap-vue'
import 'animate.css'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BImg,
    BCardText,
  },
  props: {
    tabData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vertical: false,
      tabsIndex: 0,
    }
  },
  watch: {
    width(value) {
      this.vertical = value < 764
    },
  },
  created() {
    window.addEventListener('resize', () => {
    })
  },
}
</script>

<style lang="scss">
#our_products {
  .col-auto {
    padding: 0px !important;
  }

  .tab-content {
    .tab-body {
      box-shadow: inset 0px 11px 8px -10px #CCC,
      inset 0px -11px 8px -10px #CCC;
    }

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .nav-link svg {
    margin-right: 0px !important;
  }

  //
  //.nav-item {
  //  width: 13%;
  //}

  .nav-tabs {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .nav-link {
    background-color: transparent !important;

    &::after {
      background: none !important;
    }

    &.active {
      .icon {
        color: white !important;
        background-color: #1f58b5 !important;
        margin-right: 0px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50% !important;
        height: 4rem;
        width: 4rem;
      }
    }

    .icon {
      margin-right: 0px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #0000001A !important;
      border-radius: 50% !important;
      height: 4rem;
      width: 4rem;
    }
  }

  .comp-div {
    background-color: #F8F8F8;
  }

  .heading-class {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 2rem;
    font-weight: 900;
    height: 100px;
    letter-spacing: 0px;
    color: #262626;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-class {
    top: 1200px;
    font-size: 1.5rem;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Red Hat Display', sans-serif;
    color: #262626;
    opacity: 1;
  }

  .img-class {
    top: 1458px;
    left: 363px;
    opacity: 1;
  }

  .divider-class {
    height: 3px;
    width: 80px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background: #1F58B5;
    opacity: 1;
  }

  .tab_div {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .tab-heading {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 1rem;
    font-weight: 900;
    color: #484848;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab-content-data {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #777F88;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
